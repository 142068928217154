<template>
  <section>
    <v-container class="container-custom">
      <v-row>
        <v-col md="12">
          <div class="titleAction">
            <h1 class="systemTitle">Insurance Comapanies</h1>
            <router-link to="/business/addinsurancecompany"
              ><v-btn large>
                <v-icon class="mr-2"> mdi-plus </v-icon> Add Insurance Company</v-btn
              ></router-link
            >
          </div>

          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr class="greenBack">
                  <th class="text-left">Name</th>
                  <th class="text-left">Calories</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in attendances" :key="item.name">
                  <td>{{ item.name }}</td>
                  <td>{{ item.calories }}</td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td colspan="2">
                    <ul class="pagination">
                      <li><v-icon> mdi-chevron-left </v-icon></li>
                      <li>1 - 10 Items</li>
                      <li><v-icon> mdi-chevron-right </v-icon></li>
                    </ul>
                  </td>
                </tr>
              </tfoot>
            </template>
          </v-simple-table></v-col
        >
      </v-row>
    </v-container>
  </section>
</template>

<script>
export default {
  name: "InsuranceCompany",
  data: () => ({
    attendances: [
      {
        name: "Frozen Yogurt",
        calories: 159,
      },
      {
        name: "Ice cream sandwich",
        calories: 237,
      },
      {
        name: "Eclair",
        calories: 262,
      },
      {
        name: "Cupcake",
        calories: 305,
      },
      {
        name: "Gingerbread",
        calories: 356,
      },
      {
        name: "Jelly bean",
        calories: 375,
      },
      {
        name: "Lollipop",
        calories: 392,
      },
      {
        name: "Honeycomb",
        calories: 408,
      },
      {
        name: "Donut",
        calories: 452,
      },
      {
        name: "KitKat",
        calories: 518,
      },
    ],
  }),
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/global.scss";
.pagination {
  list-style: none;
  padding-left: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .v-icon {
    font-size: 18px;
    height: 30px;
    width: 30px;
    display: flex;
  }
}
.titleAction {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  a {
    text-decoration: none;
  }
}
</style>